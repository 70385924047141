<template>
  <div class="content">
    <h1 class="page-title fs-2500">
      Schéma pluriannuel d'accessibilité <br />2022 - 2024 de Portailpro.gouv
    </h1>

    <p>
      L’article 47 de la loi n° 2005-102 du 11 février 2005 pour l’égalité des
      droits et des chances, la participation et la citoyenneté des personnes
      rend obligatoire à tout service de communication publique en ligne d’être
      accessible à tous.
    </p>
    <h2 class="second-header">Politique d’accessibilité</h2>
    <p>
      L’accessibilité numérique est une préoccupation majeure liée au
      développement ou à la mise à disposition de sites web ou d’applications
      auprès du public.
    </p>
    <p>
      Cette volonté s’illustre par l’élaboration de ce schéma pluriannuel
      d’accessibilité numérique associé à des plans annuels d’action, dans
      l’objectif d’accompagner la mise en conformité au référentiel général
      d'amélioration de l'accessibilité (RGAA) et l’amélioration progressive des
      sites web et applications concernés.
    </p>
    <p>
      L’élaboration, le suivi et la mise à jour de ce schéma pluriannuel sont
      confiés à la Mission France Recouvrement, rattachée au Secrétariat général
      du Ministère de l’Economie, des finances et de la relance.
    </p>
    <p>
      Sous couvert du Secrétariat général du Ministère de l’Economie, des
      finances et de la relance, il revient à la dite Mission de promouvoir
      l’accessibilité par la diffusion des normes et des bonnes pratiques,
      d’assurer l’accompagner des équipes de Portailpro.gouv par des actions de
      formations et de suivi d’interventions, de contrôler et de veiller à
      l’application de la loi nº 2005-102 du 11 février 2005 et d’assurer de
      manière générale la qualité du service rendu aux utilisateurs en situation
      de handicap et la prise en charge de leurs demandes.
    </p>
    <h2 class="second-header">
      Ressources humaines et financières affectées à l’accessibilité numérique
    </h2>
    <p>
      Des besoins humains et financiers ont déjà été alloués à l’accessibilité
      numérique en vue de la mise en service de Portailpro.gouv.
    </p>
    <p>
      Une ligne budgétaire dédiée à l’accessibilité numérique sera envisagée
      annuellement pour mener à bien les actions de contrôle et de correction
      des anomalies techniques.
    </p>
    <p>
      Un coordinateur RGAA apportera un support aux équipes agiles de
      Portailpro.gouv pour la prise en compte des critères du RGAA dans les
      développements.
    </p>
    <h2 class="second-header">
      Organisation de la prise en compte de l’accessibilité numérique
    </h2>
    <p>
      La prise en compte de l’accessibilité numérique nécessite une adaptation
      de l’organisation interne de production et de gestion des sites web et
      applications concernés, l’accompagnement des personnels, l’intégration en
      tant que de besoin de rubriques spécifiques dans les cahiers des charges
      des achats publics de prestations de maitrise d’ouvrage, conception
      d’expérience utilisateur, maitrise d’œuvre et recette informatique, et
      enfin la prise en charge des personnes en situation de handicap
      lorsqu’elles signalent des difficultés.
    </p>
    <p>
      Les éléments ci-dessous décrivent les points importants sur lesquels
      Mission France Recouvrement va s’appuyer pour améliorer l’accessibilité
      numérique de l’ensemble du site Portaipro.gouv.
    </p>
    <h2 class="second-header">Action de formation et de sensibilisation</h2>
    <p>
      Tout au long de la période d’application de ce schéma, des actions de
      formation et de sensibilisation seront organisées afin de permettre aux
      personnels intervenant sur Portailpro.gouv, de développer, d’éditer et
      mettre en ligne des contenus accessibles.
    </p>
    <p>
      Ces actions seront également intégrées au parcours de formation des
      nouveaux agents.
    </p>
    <h2 class="second-header">Recours à des compétences externes</h2>
    <p>
      Il pourra être fait appel à des intervenants externes afin d’accompagner
      les équipes de Portailpro.gouv dans la prise en compte de l’accessibilité,
      par exemple les actions de sensibilisation et de formation, ou encore pour
      la réalisation d’audit de conformité.
    </p>
    <h2 class="second-header">
      Prise en compte de l’accessibilité numérique dans les projets
    </h2>
    <p>
      La Mission France Recouvrement intègre l’accessibilité comme une
      composante majeure dans la création de Portailpro.gouv. Les objectifs
      d’accessibilité et de conformité au RGAA sont présents dans l’élaboration
      du projet dans son ensemble.
    </p>
    <p>
      Un coordinateur RGAA apportera un support aux équipes agiles du site pour
      la prise en compte des critères d’accessibilité dans les développements.
    </p>
    <h2 class="second-header">
      Prise en compte de l’accessibilité dans les procédures de marché
    </h2>
    <p>
      L’accessibilité numérique et la conformité au RGAA doivent constituer une
      clause contraignante lors de la commande de travaux au travers des appels
      d’offres notamment. Les procédures d’élaboration des marchés ainsi que les
      règles d’évaluation des candidatures seront adaptées pour prendre en
      compte les exigences de conformité au RGAA, et définir les modalités
      d’évaluation des candidats dans les appels d’offres.
    </p>
    <h2 class="second-header">Recrutement</h2>
    <p>
      Une attention particulière va être portée sur les compétences en matière
      d’accessibilité numérique des personnels intervenant sur les services
      numériques, lors de la création des fiches de postes et les procédures de
      recrutement.
    </p>
    <p>
      Ces compétences sont déjà exigées sur certains postes de chefs de projets.
    </p>
    <p>
      À défaut de compétences préexistantes, les nouveaux agents se verront
      proposer un parcours de sensibilisation ou de formation.
    </p>
    <h2 class="second-header">Traitement des retours utilisateurs</h2>
    <p>
      Conformément aux dispositions prévues par le RGAA et aux attentes
      légitimes des utilisateurs, un moyen de contact va être mis en place,
      permettant aux utilisateurs en situation de handicap de signaler ses
      difficultés.
    </p>
    <p>
      Les demandes peuvent être adressées par les usagers vers une boîte
      courriel dédiée (<a
        class="fr-link"
        href="mailto:mfr.rgaa@finances.gouv.fr"
        ><span>mfr.rgaa@finances.gouv.fr</span></a
      >) pour être analysées par les services concernés, ainsi qu'auprès du
      responsable de l'élaboration, de la mise en place et du suivi du présent
      schéma pluriannuel.
    </p>
    <h2 class="second-header">Processus de contrôle et de validation</h2>
    <p>
      Des audits d’accessibilité ont été effectués sur la version initiale du
      site permettant d’établir une déclaration d’accessibilité.
    </p>
    <p>
      Lors d’une mise à jour substantielle, d’une refonte ou à la fin des
      opérations de mises aux normes, le site Portailpro.gouv fera l’objet d’un
      contrôle permettant d’établir une nouvelle déclaration d’accessibilité
      conformément aux termes de la loi.
    </p>
    <p>
      Pour en garantir la sincérité et l’indépendance, ce contrôle sera effectué
      par l’intermédiaire d’un intervenant externe spécialisé.
    </p>
    <p>
      Ces opérations de contrôle destinées à l’établissement ou la mise à jour
      des déclarations de conformité interviennent en complément des opérations
      habituelles de recette et contrôles intermédiaires qui seront organisées,
      si nécessaire, tout au long de la vie des projets.
    </p>

    <h2 class="second-header">Processus de contrôle et de validation</h2>
    <p>
      Des audits d’accessibilité ont été effectués sur la version initiale du
      site permettant d’établir une déclaration d’accessibilité.
    </p>
    <p>
      Lors d’une mise à jour substantielle, d’une refonte ou à la fin des
      opérations de mises aux normes, le site Portailpro.gouv fera l’objet d’un
      contrôle permettant d’établir une nouvelle déclaration d’accessibilité
      conformément aux termes de la loi.
    </p>
    <p>
      Pour en garantir la sincérité et l’indépendance, ce contrôle sera effectué
      par l’intermédiaire d’un intervenant externe spécialisé.
    </p>
    <p>
      Ces opérations de contrôle destinées à l’établissement ou la mise à jour
      des déclarations de conformité interviennent en complément des opérations
      habituelles de recette et contrôles intermédiaires qui seront organisées,
      si nécessaire, tout au long de la vie des projets.
    </p>

    <h2 class="second-header">Périmètre technique et fonctionnel</h2>
    <span class="soustitre">Recensement</span>
    <p class="undersub">
      La Mission France Recouvrement gère le site internet Portailpro.gouv. Le
      périmètre technique et fonctionnel du site est organisé autour de deux
      services :
    </p>
    <ul>
      <li>
        le service dit non-authentifié, ne nécessitant pas de création de compte
        pour la consultation et la navigation de ce dernier ;
      </li>
      <li>
        le service dit authentifié, nécessitant la création d’un compte
        utilisateur (ou la connexion à ce dernier) pour la consultation et la
        navigation de ce dernier.
      </li>
    </ul>
    <span class="soustitre">Évaluation et qualification</span>
    <p class="undersub">
      Le site a été qualifié selon des critères tels que la fréquentation, le
      service rendu, la criticité, le cycle de vie (date de la prochaine
      refonte) ou encore les technologies employées.
    </p>
    <span class="soustitre">Agenda planifié des interventions</span>
    <p class="undersub">
      Compte tenu des informations recueillies lors de l’élaboration de ce
      schéma, la complexité du site, leur classement par ordre de priorité et
      leur évaluation en matière de faisabilité, les opérations de mise en
      conformité vont s’étaler sur les années 2022 à 2024.
    </p>

    <h2 class="second-header">Plans annuels</h2>
    <p>
      Ce schéma pluriannuel est accompagné de plans annuels d’actions qui
      décrivent en détail les opérations mises en œuvre pour prendre en charge
      l’ensemble des besoins en matière d’accessibilité numérique.
    </p>
    <div class="fr-table">
      <table>
        <caption>
          Liste des plans annuels
        </caption>
        <thead>
          <tr>
            <th scope="col">Nom</th>
            <th scope="col">Consultation</th>
            <th scope="col">Dernière mise à jour</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Plan annuel 2022</td>
            <td>
              <router-link
                class="fr-link"
                :to="{
                  name:
                    'Plan annuel d’accessibilité pour 2022 de Portailpro.gouv'
                }"
                alt="plan annuel d'accessibilite pour 2022 de portailprogouv"
                ><span
                  >https://www.portailpro.gouv.fr/ms/plan-annuel-daccessibilite-pour-2022-de-portailprogouv</span
                ></router-link
              >
            </td>
            <td>14/01/2022</td>
          </tr>
          <tr>
            <td>Plan annuel 2023</td>
            <td>
              <router-link
                class="fr-link"
                :to="{
                  name:
                    'Plan annuel d’accessibilité pour 2023 de Portailpro.gouv'
                }"
                alt="plan annuel d'accessibilite pour 2023 de portailprogouv"
                ><span
                  >https://www.portailpro.gouv.fr/ms/plan-annuel-daccessibilite-pour-2023-de-portailprogouv</span
                ></router-link
              >
            </td>
            <td>01/05/2023</td>
          </tr>
          <tr>
            <td>Plan annuel 2024</td>
            <td>À venir</td>
            <td>À venir</td>
          </tr>
        </tbody>
      </table>
    </div>

    <AccessibiliteFooterNav />
  </div>
</template>

<script>
import SkipLinkMixin from '../mixins/skip-link-mixin';
import AccessibiliteFooterNav from '../components/AccessibiliteFooterNav.vue';

export default {
  mixins: [SkipLinkMixin],

  components: {
    AccessibiliteFooterNav
  }
};
</script>

<style lang="scss" scoped>
.content {
  width: 70%;
}
h2 {
  font-weight: bold;
  margin-bottom: 1rem;
}
a {
  box-shadow: none;
  color: $base-color !important;
  background-image: none;
}
a::before {
  width: 1px;
  height: 1px;
}
p {
  margin-bottom: 1.5rem;
}
li {
  margin-bottom: 1rem;
}
.bold {
  font-weight: bold;
  font-style: italic;
}
.fr-link {
  text-decoration: underline !important;
}
a:focus {
  text-decoration: underline !important;
}
i {
  margin-left: 0.5rem;
  color: $base-color;
}
.soustitre {
  font-family: Marianne;
  font-size: 26px;
  font-weight: bold;
}
.undersub {
  margin-top: 1rem;
}
table thead th:nth-child(2) {
  width: 50%;
}

@media only screen and (max-width: 760px) {
  .content {
    width: 100%;
  }
  .fr-table table {
    display: block;
    overflow: auto;
  }
  table thead th:nth-child(2) {
    width: auto;
  }
}
</style>
